
import md5 from 'md5'

export const calculateHashes = (file) => {
  return readBinaryFile(file)
    .then(async function (result) {
      const uint8arr = new Uint8Array(result)
      return {
        sha256: await window.crypto.subtle.digest('SHA-256', uint8arr),
        md5: md5(uint8arr),
      }
    })
    .then(function (result) {
      const uint8arr = new Uint8Array(result.sha256)
      return {
        sha256: Uint8ArrayToHexString(uint8arr),
        md5: result.md5,
      }
    })
}

const readBinaryFile = (file) => {
  return new Promise((resolve, reject) => {
    const fr = new FileReader()
    fr.onload = () => {
      resolve(fr.result)
    }
    fr.readAsArrayBuffer(file)
  })
}

const Uint8ArrayToHexString = (ui8array) => {
  let hexString = ''
  let h
  for (let i = 0; i < ui8array.length; i++) {
    h = ui8array[i].toString(16)
    if (h.length === 1) {
      h = '0' + h
    }
    hexString += h
  }
  const p = Math.pow(2, Math.ceil(Math.log2(hexString.length)))
  hexString = hexString.padStart(p, '0')
  return hexString
}
