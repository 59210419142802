import Vue from 'vue'

import { dateFormat, dateTimeFormat } from '~/filters/dateFormats'
import timeDifference from '~/filters/timeDifference'
import timerFormat from '~/filters/timerFormat'
import toCase from '~/filters/toCase'
import toPrice from '~/filters/toPrice'
import truncateText from '~/filters/truncateText'

Vue.filter('timeDifference', timeDifference)
Vue.filter('dateFormat', dateFormat)
Vue.filter('dateTimeFormat', dateTimeFormat)
Vue.filter('price', toPrice)
Vue.filter('timerFormat', timerFormat)
Vue.filter('toCase', toCase)
Vue.filter('truncate', truncateText)
