const formatTimeValue = value => value < 10 ? `0${value}` : `${value}`

export default (value, showSeconds = false) => {
  if (!value) return `00:00${showSeconds ? ':00' : ''}`

  const x = parseInt(value, 10) ?? 0

  const hourMill = 3600000
  const minuteMill = 60000
  const secondMill = 1000

  const hours = Math.floor(x / hourMill)
  const remainingMinMills = x % hourMill
  const minutes = Math.floor(remainingMinMills / minuteMill)
  const remainingSecMills = remainingMinMills % minuteMill
  const seconds = Math.floor(remainingSecMills / secondMill)

  return `${formatTimeValue(hours)}:${formatTimeValue(minutes)}` +
    (showSeconds
      ? `:${formatTimeValue(seconds)}`
      : ''
    )
}
