import { kebab } from '~/filters/toCase'

export const updateSubject = function ({ data, key, keyId, subjectId }) {
  if ((key && !keyId) || (keyId && !key)) {
    throw new Error('A key or keyId is required to update Subject')
  }

  if (!key) {
    return this.$axios.patch(`subjects/${subjectId}`, data)
  }

  return this.$axios.put(`subjects/${subjectId}/${kebab(key)}/${keyId}`, data)
}
