import https from 'https'
import http from 'http'
import createAuthRefreshInterceptor from 'axios-auth-refresh'

export default (context) => {
  const { $axios, error: nuxtError, store } = context

  $axios.defaults.httpsAgent = new https.Agent({ keepAlive: true })
  $axios.defaults.httpAgent = new http.Agent({ keepAlive: true })

  const refreshAuthLogic = (failedRequest) => {
    return new Promise((resolve, reject) => {
      const isSameOrigin = (url) => {
        return new URL(store.state.main.smiApiUrl).origin === new URL(url).origin
      }

      if (failedRequest?.response?.status === 401 && isSameOrigin(failedRequest?.response?.request?.responseURL) && window.location.pathname !== '/login') {
        context.store.dispatch('logoutNoAuth')
        window.location.href = '/login'

        // If we do not resolve the response we do not see the error page.
        // resolve()
      } else {
        reject(failedRequest)
      }
    })
  }

  // Instantiate the interceptor
  createAuthRefreshInterceptor($axios, refreshAuthLogic)

  $axios.onResponseError((error) => {
    if (![400, 401, 403].includes(error?.response?.status)) {
      nuxtError({
        statusCode: error?.response?.status,
        statusText: error?.response?.statusText,
        message: error?.message,
        response: {
          method: error?.response?.config?.method?.toUpperCase(),
          url: [
            error?.response?.config?.baseURL
              .replace('//', '\\')
              .replace('/', ''),
            error?.response?.config?.url,
          ].join('/').replace('\\', '//'),
        },
        stack: error?.stack,
      })
      return Promise.resolve(false)
    }

    return error.response
  })
}
