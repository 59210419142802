export default {
  async impersonateUser ({ commit }, userId) {
    const response = await this.$axios.$get(`/auth/impersonate/${userId}`)
    const newToken = response?.impersonationToken?.token

    if (newToken) {
      const originalToken = this.$auth.$storage.getCookie('_token.local')

      this.$auth.$storage.setCookie('original_token', originalToken.replace('Bearer ', ''))

      commit('setIsImpersonating', true)

      await this.$auth.setUserToken(newToken)

      this.$router.push('/')
    }
  },
  async unimpersonate ({ commit }) {
    const originalToken = this.$auth.$storage.getCookie('original_token')

    this.$auth.$storage.removeCookie('original_token')

    commit('setIsImpersonating', false)

    await this.$auth.setUserToken(originalToken)
  },
  setIsImpersonating ({ commit }, value) {
    commit('setIsImpersonating', value)
  },
}
