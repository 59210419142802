import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInMonths,
  differenceInYears
} from 'date-fns'

export default (value, endDate) => {
  if (!value) return null

  const valueDate = new Date(value)

  endDate = endDate ? new Date(endDate) : new Date()

  const years = differenceInYears(endDate, valueDate)

  if (years) return `${years} year${years > 1 ? 's' : ''}`

  const months = differenceInMonths(endDate, valueDate)

  if (months) return `${months} month${months > 1 ? 's' : ''}`

  const days = differenceInDays(endDate, valueDate)

  if (days) return `${days} day${days > 1 ? 's' : ''}`

  const hours = differenceInHours(endDate, valueDate)

  if (hours) return `${hours} hour${hours > 1 ? 's' : ''}`

  const minutes = differenceInMinutes(endDate, valueDate)

  if (minutes) return `${minutes} minute${minutes > 1 ? 's' : ''}`

  return '<1 minute'
}
