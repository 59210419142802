
import { mapActions, mapState } from 'vuex'

export default {
  props: {
    footer: {
      default: true,
      type: Boolean,
    },
  },
  data () {
    return {
      snackbar: {
        color: null,
        message: null,
        show: false,
        timeout: null,
      },
    }
  },
  computed: {
    ...mapState('notifications', {
      newNotification: 'newNotification',
    }),
  },
  watch: {
    async newNotification (newValue, oldValue) {
      if (newValue) {
        await this.showNotification()
      }
    },
  },
  methods: {
    ...mapActions('notifications', {
      fetchNextNotification: 'fetchNextNotification',
    }),
    async resetSnackbar () {
      this.snackbar = {
        color: null,
        message: null,
        show: false,
        timeout: null,
      }
      await this.showNotification()
    },
    async showNotification () {
      const notification = await this.fetchNextNotification()

      if (notification) {
        this.snackbar = notification
        this.snackbar.show = true
      }
    },
  },
}
