import { format, utcToZonedTime } from 'date-fns-tz'

export const dateFormat = (value, formatString = 'MM/dd/yyyy', timeZone) => {
  return value
    ? format(
      timeZone
        ? utcToZonedTime(new Date(value), timeZone)
        : new Date(value),
      formatString,
      { timeZone }
    )
    : null
}

export const dateTimeFormat = (value, formatString = 'MM/dd/yyyy hh:mm:ss aaa z', timeZone) => {
  return value
    ? format(
      timeZone
        ? utcToZonedTime(new Date(value), timeZone)
        : new Date(value),
      formatString,
      { timeZone }
    )
    : null
}
