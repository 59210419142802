
export const determinePlatformType = function (finding, platforms) {
  const fileType = platforms.find(x => x.name === 'File')
  const webType = platforms.find(x => x.name === 'Web Result')

  if (finding.fileName) return fileType.id

  const url = finding.url.toLowerCase()
  const platform = platforms.find(x => x.domain && url.includes(x.domain?.toLowerCase()))

  return platform
    ? platform.id
    : url.includes(this.state.main.privateCdnUrl)
      ? fileType.id
      : webType.id
}

export const calculateBoilerplates = (platformTypeId, confirmed, platformTypes, companyName) => {
  const selectedPlatform = platformTypes.find(x => x.id === platformTypeId)
  const boilerplates = []

  // JDP
  if (companyName === 'JD Palatine LLC.') {
    if (confirmed) {
      boilerplates.push(
        {
          text: 'JDP, Confirmed',
          content: 'This is a confirmed match for the subject. ' +
            '{result-details}',
          placeholders: [
            {
              id: 'result-details',
              value: '',
            },
          ],
          controls: [],
        }
      )
    } else {
      boilerplates.push(
        {
          text: 'JDP, Unconfirmed',
          content: 'Due to a lack of identifiers, we are unable to confirm this result. ' +
            '{result-details}',
          placeholders: [
            {
              id: 'result-details',
              value: '',
            },
          ],
          controls: [],
        }
      )
    }

    boilerplates.push(
      {
        text: 'JDP, Keyword, Non-Negative',
        content: 'The subject uses the keyword approximately {number}. The subject does not use the keyword in a negative manner. ' +
          '{result-details}',
        placeholders: [
          {
            id: 'number',
            value: 'NUMBER times',
          },
          {
            id: 'result-details',
            value: '',
          },
        ],
        controls: [
          {
            id: 'number',
            type: 'v-text-field',
            label: 'Keyword Count',
            value: null,
            defaultValue: null,
          },
        ],
      },
      {
        text: 'JDP, Keyword, Negative',
        content: 'The subject uses the keyword approximately {number}. The subject uses the keyword in a negative manner. ' +
          '{result-details}',
        placeholders: [
          {
            id: 'number',
            value: 'NUMBER times',
          },
          {
            id: 'result-details',
            value: '',
          },
        ],
        controls: [
          {
            id: 'number',
            type: 'v-text-field',
            label: 'Keyword Count',
            value: null,
            defaultValue: null,
          },
        ],
      },
      {
        text: 'JDP, Similar Content',
        content: 'There are several results containing similar content on this profile. ' +
          '{result-details}',
        placeholders: [
          {
            id: 'result-details',
            value: '',
          },
        ],
        controls: [],
      }
    )
  }

  // LinkedIn
  if (selectedPlatform.name === 'LinkedIn' && confirmed) {
    boilerplates.push(
      {
        text: 'LinkedIn, Confirmed',
        content: 'This profile matches the subject\'s {identifiers}. ' +
          'This is a confirmed result for the subject. ' +
          '{work-experience}' +
          '{education}' +
          '{result-details}',
        placeholders: [
          {
            id: 'identifiers',
            value: 'IDENTIFIERS',
          },
          {
            id: 'result-details',
            value: '',
          },
          {
            id: 'work-experience',
            value: '',
          },
          {
            id: 'education',
            value: '',
          },
        ],
        controls: [
          {
            id: 'work-experience',
            type: 'v-text-field',
            label: 'Work Experience',
            value: '',
            defaultValue: '',
            prefix: 'The subject lists themselves as an employee at',
          },
          {
            id: 'education',
            type: 'v-text-field',
            label: 'Education',
            value: '',
            defaultValue: '',
            prefix: 'The subject has attended',
          },
        ],
      },
      {
        text: 'LinkedIn, Confirmed, No Information',
        content: 'This profile matches the subject\'s {identifiers}. ' +
          'This is a confirmed result for the subject. No posts or published content is visible. ' +
          '{result-details}',
        placeholders: [
          {
            id: 'identifiers',
            value: 'IDENTIFIERS',
          },
          {
            id: 'result-details',
            value: '',
          },
        ],
        controls: [],
      }
    )
    return boilerplates
  }

  // Snapchat
  if (selectedPlatform.name === 'Snapchat' && confirmed) {
    boilerplates.push(
      {
        text: 'Snapchat, Confirmed',
        content: 'This profile {matches}. Due to the nature of this mobile application, we are unable to review the contents of this profile. ' +
        '{result-details}',
        placeholders: [
          {
            id: 'matches',
            value: 'MATCHES THE SUBJECT\'S CONFIRMED USERNAMES/WAS LINKED TO FROM A CONFIRMED PROFILE',
          },
          {
            id: 'result-details',
            value: '',
          },
        ],
        controls: [
          {
            id: 'matches',
            type: 'v-checkbox',
            label: 'Matches Criteria',
            items: ['matches the subject\'s confirmed usernames', 'was linked to from a confirmed profile'],
            value: [],
            defaultValue: [],
          },
        ],
      }
    )
    return boilerplates
  }

  // Google Maps
  if (selectedPlatform.name === 'Google Maps' && confirmed) {
    boilerplates.push(
      {
        text: 'Google Maps, Confirmed',
        content: 'This result was produced from the subject\'s last recorded address{no-records}. ' +
          '{map-details}' +
          'The image below shows the relative location the subject was last recorded to have a permanent residence at. ' +
          'NOTE: Because this result is generated by Google Maps, the above URL is a safe link to open in your browser. ' +
          'By opening the URL for this result, you can better view the subject\'s most likely place of residence for relevant information, ' +
          'opportunities for surveillance, or general knowledge about the subject. ' +
          '{result-details}',
        placeholders: [
          {
            id: 'no-records',
            value: '',
          },
          {
            id: 'map-details',
            value: '',
          },
          {
            id: 'result-details',
            value: '',
          },
        ],
        controls: [
          {
            id: 'map-details',
            type: 'v-textarea',
            label: 'Map Details',
            value: '',
            defaultValue: '',
          },
          {
            id: 'no-records',
            type: 'v-checkbox',
            label: 'Public Records',
            items: ['No public records'],
            value: [],
            defaultValue: [],
          },
        ],
      }
    )

    return boilerplates
  }

  // File
  if (selectedPlatform.name === 'File') {
    boilerplates.push(
      {
        text: 'Tracers',
        content: 'This generated report details the subject\'s {details}. {result-details}',
        placeholders: [
          {
            id: 'details',
            value: 'LIENS/BANKRUPTCIES/FORECLOSURES/CRIMINAL RECORD/ETC',
          },
          {
            id: 'result-details',
            value: 'DETAIL THE RESULTS TO INCLUDE DATE OF FILE AND LOCATION, IF POSSIBLE. ',
          },
        ],
        controls: [
          {
            id: 'details',
            type: 'v-select',
            label: 'Matches Criteria',
            items: [
              { id: 'Tax Lien', value: 'tax lien' },
              { id: 'Foreclosure', value: 'foreclosure' },
              { id: 'Bankruptcy', value: 'bankruptcy' },
              { id: 'Judgment', value: 'judgment' },
              { id: 'Domains', value: 'domains' },
              { id: 'DBA/FBN Records', value: 'DBA/FBN records' },
              { id: 'FEIN Records', value: 'FEIN records' },
              { id: 'Corporate Records', value: 'corporate records' },
              { id: 'Workplace Records', value: 'workplace records' },
              { id: 'UCC Filings', value: 'UCC filings' },
              { id: 'Arrest Records', value: 'arrest records' },
              { id: 'Criminal Records', value: 'criminal records' },
              { id: 'Sex Offender Records', value: 'sex offender records' },
              { id: 'National Warrants Records', value: 'national warrants records' },
              { id: 'Traffic Records', value: 'traffic records' },
              { id: 'Evictions', value: 'evictions' },
              { id: 'DEA Licenses', value: 'DEA licenses' },
              { id: 'Hunting & Fishing Licenses', value: 'hunting & fishing licenses' },
              { id: 'Professional Licenses', value: 'professional licenses' },
            ],
            value: null,
            defaultValue: null,
          },
        ],
      },
      {
        text: 'Other',
        content: '{result-details}',
        placeholders: [
          {
            id: 'result-details',
            value: 'DETAIL THE RESULTS TO INCLUDE DATE OF FILE AND LOCATION, IF POSSIBLE. ',
          },
        ],
        controls: [],
      }
    )
    return boilerplates
  }

  // Web Results
  if (selectedPlatform.name === 'Web Result' && confirmed) {
    boilerplates.push(
      {
        text: 'Criminal Record',
        content: 'This web result matches the subject\'s {identifiers}. ' +
          'This is a confirmed result for the subject. ' +
          '{record-details}' +
          'Due to the search requirements on the records site, the URL may not directly link to the result. ' +
          '{criteria-used}' +
          '{result-details}',
        placeholders: [
          {
            id: 'identifiers',
            value: 'IDENTIFIERS',
          },
          {
            id: 'record-details',
            value: 'RECORD DETAILS. ',
          },
          {
            id: 'criteria-used',
            value: '',
          },
          {
            id: 'result-details',
            value: '',
          },
        ],
        controls: [
          {
            id: 'record-details',
            type: 'v-textarea',
            label: 'Record Details',
            value: '',
            defaultValue: '',
          },
          {
            id: 'criteria-used',
            type: 'v-checkbox',
            label: 'Search Criteria',
            items: ['Subject\'s Name', 'Location', 'Date of Birth'],
            value: [],
            defaultValue: [],
            prefix: 'The search criteria used on the site includes the',
          },
        ],
      },
      {
        text: 'Web Result, Confirmed',
        content: 'This web result matches the subject\'s {identifiers}. ' +
          'This is a confirmed result for the subject. ' +
          '{result-details}',
        placeholders: [
          {
            id: 'identifiers',
            value: 'IDENTIFIERS',
          },
          {
            id: 'result-details',
            value: 'DESCRIBE WHAT SUBJECT POSTS ABOUT, PROFILE CONTENTS, ETC. WITH SCOPE RELATED MATERIAL FIRST. ',
          },
        ],
        controls: [],
      }
    )
    return boilerplates
  }

  if (selectedPlatform.name === 'Web Result' && !confirmed) {
    boilerplates.push(
      {
        text: 'Web Result, Unconfirmed',
        content: 'This web result matches the subject\'s {identifiers}, but without further identifiers, we are unable to confirm this result. {result-details}',
        placeholders: [
          {
            id: 'identifiers',
            value: 'IDENTIFIERS',
          },
          {
            id: 'result-details',
            value: 'ANALYST DESCRIPTION OF RESULT. ',
          },
        ],
        controls: [],
      })
    return boilerplates
  }

  // Other Platforms
  if (selectedPlatform && confirmed) {
    boilerplates.push(
      {
        text: 'Profile, Confirmed',
        content: 'This profile matches the subject\'s {identifiers}. ' +
          'This is a confirmed result for the subject. ' +
          '{last-active}' +
          '{result-details}',
        placeholders: [
          {
            id: 'identifiers',
            value: 'IDENTIFIERS',
          },
          {
            id: 'result-details',
            value: 'DESCRIBE WHAT SUBJECT POSTS ABOUT, PROFILE CONTENTS, ETC. WITH SCOPE RELATED MATERIAL FIRST. ',
          },
          {
            id: 'last-active',
            value: 'LAST ACTIVE DATE. ',
          },
        ],
        controls: [],
      },
      {
        text: 'Profile, Confirmed, Private',
        content: 'This profile matches the subject\'s {identifiers}. ' +
          'This is a confirmed result for the subject. ' +
          'No posts or published content is visible. The profile is private, limiting the amount of publicly-visible content. ' +
          '{result-details}',
        placeholders: [
          {
            id: 'identifiers',
            value: 'IDENTIFIERS',
          },
          {
            id: 'result-details',
            value: '',
          },
        ],
        controls: [],
      },
      {
        text: 'Profile, Confirmed, Likely Privacy Settings/Low Content',
        content: 'This profile matches the subject\'s {identifiers}. ' +
          'This is a confirmed result for the subject. ' +
          '{last-active}' +
          'Enhanced privacy settings may be in place that limits the amount of publicly-visible content. ' +
          '{result-details}',
        placeholders: [
          {
            id: 'identifiers',
            value: 'IDENTIFIERS',
          },
          {
            id: 'last-active',
            value: 'LAST ACTIVE DATE. ',
          },
          {
            id: 'result-details',
            value: 'DESCRIBE WHAT SUBJECT POSTS ABOUT, PROFILE CONTENTS, ETC. WITH SCOPE RELATED MATERIAL FIRST. ',
          },

        ],
        controls: [],
      },
      {
        text: 'Profile, Confirmed, No Content',
        content: 'This profile matches the subject\'s {identifiers}. ' +
          'This is a confirmed result for the subject. No posts or published content is visible. ' +
          'Enhanced privacy settings may be in place that limits the amount of publicly-visible content. ' +
          '{result-details}',
        placeholders: [
          {
            id: 'identifiers',
            value: 'IDENTIFIERS',
          },
          {
            id: 'result-details',
            value: '',
          },
        ],
        controls: [],
      }
    )
    return boilerplates
  }

  if (selectedPlatform && !confirmed) {
    boilerplates.push(
      {
        text: 'Profile, Unconfirmed',
        content: 'This profile matches the subject\'s {identifiers}, but without further identifiers, we are unable to confirm this result. ' +
        '{last-active}' +
        '{result-details}',
        placeholders: [
          {
            id: 'identifiers',
            value: 'IDENTIFIERS',
          },
          {
            id: 'last-active',
            value: 'LAST ACTIVE DATE. ',
          },
          {
            id: 'result-details',
            value: 'DESCRIBE WHAT SUBJECT POSTS ABOUT, PROFILE CONTENTS, ETC. WITH SCOPE RELATED MATERIAL FIRST. ',
          },
        ],
        controls: [],
      },
      {
        text: 'Profile, Unconfirmed, No Content',
        content: 'This profile matches the subject\'s {identifiers}, but without further identifiers, we are unable to confirm this result. ' +
          'No posts or published content is visible. ' +
          'Enhanced privacy settings may be in place that limit the amount of publicly-visible content. ' +
          '{result-details}',
        placeholders: [
          {
            id: 'identifiers',
            value: 'IDENTIFIERS',
          },
          {
            id: 'result-details',
            value: '',
          },
        ],
        controls: [],
      }
    )
    return boilerplates
  }

  return boilerplates
}
