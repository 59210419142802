export default {
  async loadCalendarEvents ({ commit }, date) {
    const eventDate = new Date(`${date}-01T00:00:00`)
    const calendarEvents = await this.$axios.$post('calendar-events/fetch', {
      month: eventDate.getMonth(),
      year: eventDate.getFullYear(),
    })

    commit('setCalendarEvents', calendarEvents)
  },
  saveDashboardSettings ({ commit }, settings) {
    commit('setDashboardSettings', settings)

    Object.entries(settings).forEach(([key, value]) => {
      localStorage.setItem(key, value)
    })
  },

}
