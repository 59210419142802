export default {
  setCompanyName (state, value) {
    state.companyName = value
  },
  setDeepReportId (state, value) {
    state.deepReportId = value
  },
  setExpandedSubjectFields (state, value) {
    state.expandedSubjectFields = value
  },
  setFileUploading (state, value) {
    state.fileUploading = value
  },
  setFindingCategories (state, value) {
    state.findingCategories = value
  },
  setFindings (state, value) {
    state.findings = value
  },
  setFindingTags (state, value) {
    state.findingTags = value
  },
  setSubject (state, value) {
    const subject = { ...state.subject }

    Object.entries(value).forEach(([key, val]) => {
      subject[key] = val ?? null
    })

    state.subject = subject
  },
  setPlatformTypes (state, value) {
    state.platformTypes = value
  },
}
