export default (ctx) => {
  return {
    newNotification: false,
    notifications: [],
    banner: {
      closedByUser: false,
      message: null,
      show: false,
    },
  }
}
