// State initialization required for nuxt-socket-io module
export const state = () => ({})

export const actions = {
  logout ({ dispatch }) {
    this.$auth.logout()
    this.$auth.$storage.removeCookie('original_token')
    dispatch('impersonate/setIsImpersonating', false)
  },
  logoutNoAuth ({ dispatch }) {
    this.$auth.strategies.local?.reset()
    this.$auth.$storage.removeCookie('original_token')
    dispatch('impersonate/setIsImpersonating')
  },
  nuxtClientInit ({ dispatch }, ctx) {
    dispatch('impersonate/setIsImpersonating', !!ctx.$auth?.$storage.getCookie('original_token'))
  },
}
