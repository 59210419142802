
export const state = () => ({
  expanded: false,
})

export const mutations = {
  set (state, value) {
    state.expanded = value
  },
  toggle (state) {
    state.expanded = !state.expanded
  },
}
