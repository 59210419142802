
import { mapActions, mapState } from 'vuex'
export default {
  computed: {
    ...mapState('impersonate', {
      isImpersonating: 'isImpersonating',
    }),
  },
  methods: {
    ...mapActions({
      unimpersonate: 'impersonate/unimpersonate',
    }),
    async unimpersonateUser () {
      await this.unimpersonate()
      this.$router.push('/')
    },
  },
}
