export default (ctx) => {
  return {
    companyName: null,
    deepReportId: null,
    expandedSubjectFields: [],
    fileUploading: false,
    findingCategories: [],
    findings: [],
    findingTags: [],
    platformTypes: [],
    subject: {
      addresses: [],
      aliases: [],
      birthDate: null,
      birthDay: null,
      birthMonth: null,
      birthYear: null,
      companyName: null,
      createdAt: null,
      deletedAt: null,
      dobIsConfirmed: false,
      educationalInstitutions: [],
      emails: [],
      employeeUrls: [],
      employees: [],
      employers: [],
      estimatedAge: null,
      firstName: null,
      fullName: null,
      gender: null,
      genderIsConfirmed: false,
      id: null,
      lastName: null,
      middleName: null,
      nameIsConfirmed: false,
      phoneNumbers: [],
      relationshipUrls: [],
      relationships: [],
      surnames: [],
      type: null,
      updatedAt: null,
      urls: [],
    },
  }
}
