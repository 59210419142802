
import { mapActions, mapState } from 'vuex'
export default {
  computed: {
    ...mapState('notifications', {
      banner: 'banner',
    }),
  },
  async created () {
    if (!this.banner.closedByUser) {
      const event = await this.$axios.$post('calendar-events/check-date', { date: new Date() })

      this.setBanner({
        message: event?.bannerMessage,
        show: !!event,
      })
    }
  },
  methods: {
    ...mapActions({
      hideBanner: 'notifications/hideBanner',
      setBanner: 'notifications/setBanner',
    }),
  },
}
