export default {
  setCalendarEvents (state, value) {
    state.calendarEvents = value
  },
  setDashboardSettings (state, value) {
    state.dashboardSettings = {
      ...state.dashboardSettings,
      ...value,
    }
  },
}
