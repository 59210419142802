import { determinePlatformType } from '~/lib/findings'
import { calculateHashes } from '~/lib/hash'
import { kebab, title as toTitleCase } from '~/filters/toCase'
import { updateSubject } from '~/lib/subjects'
import { getStateAbbreviation } from '~/lib/helpers'

const valueFallback = value => value || (value === false
  ? false
  : null)

export default {
  async addFinding ({ dispatch, state }, { finding }) {
    const { data: { sequence } } = await this.$axios.get(`deep-reports/${finding.deepReportId}/max-sequence`)

    finding.platformTypeId = determinePlatformType.call(this, finding, state.platformTypes)
    finding.sequence = (sequence ?? 0) + 1

    const findingsItem = await this.$axios.$post('findings', finding)

    dispatch('pushFindings', [findingsItem])

    return findingsItem
  },
  async addSubjectEmployeeUrl ({ commit, state }, { value }) {
    const response = await this.$axios.post(`subject-employees/${value.subjectEmployeeId}/urls`, value)

    const subject = {
      ...state.subject,
      employees: state.subject.employees.map((x) => {
        const employee = { ...x }

        if (employee.id === response.data.subjectEmployeeId) {
          employee.urls = [
            ...(employee.urls ?? []),
            response.data,
          ]
        }

        return employee
      }),
      employeeUrls: value.uuid
        ? state.subject.employeeUrls.map((item) => {
          if (item.uuid === value.uuid) {
            item = response.data
          }
          return item
        })
        : [...state.subject.employeeUrls, response.data],
    }

    commit('setSubject', subject)
  },
  addNewSubjectItem ({ commit, state }, { key, value }) {
    const subject = { ...state.subject }

    subject[key] = [...subject[key], { ...value, uuid: this.$uuid() }]

    commit('setSubject', subject)
  },
  async addSubjectItem ({ commit, state }, { key, value }) {
    const entries = Object.entries(value).map(([key, value]) => {
      return [key, valueFallback(value)]
    })

    const data = Object.fromEntries(entries)

    const subjectItem = await this.$axios
      .post(
        `subjects/${state.subject.id}/${kebab(key)}`,
        data
      )

    const subject = { ...state.subject }

    if (value.uuid) {
      subject[key] = subject[key].map((item) => {
        if (item.uuid === value.uuid) {
          item = subjectItem.data
        }
        return item
      })
    } else if (subject[key].some(item => item.uuid)) {
      subject[key] = subject[key].reduce((acc, item) => {
        if (!acc.firstUuidItemReplaced && item.uuid) {
          acc.firstUuidItemReplaced = true
          acc.items.push(subjectItem.data)
        } else {
          acc.items.push(item)
        }

        return acc
      }, { items: [], firstUuidItemReplaced: false }).items
    } else {
      subject[key] = [...subject[key], subjectItem.data]
    }

    commit('setSubject', subject)
  },
  async addSubjectRelationshipUrl ({ commit, state }, { value }) {
    const response = await this.$axios.post(`subject-relationships/${value.subjectRelationshipId}/urls`, value)

    const subject = {
      ...state.subject,
      relationships: state.subject.relationships.map((x) => {
        const relationship = { ...x }

        if (relationship.id === response.data.subjectRelationshipId) {
          relationship.urls = [
            ...(relationship.urls ?? []),
            response.data,
          ]
        }

        return relationship
      }),
      relationshipUrls: value.uuid
        ? state.subject.relationshipUrls.map((item) => {
          if (item.uuid === value.uuid) {
            item = response.data
          }
          return item
        })
        : [...state.subject.relationshipUrls, response.data],
    }

    commit('setSubject', subject)
  },
  formatSubject ({ dispatch, state }) {
    dispatch('updateSubject', {
      companyName: toTitleCase(state.subject.companyName),
      firstName: toTitleCase(state.subject.firstName),
      gender: toTitleCase(state.subject.gender),
      lastName: toTitleCase(state.subject.lastName),
      middleName: toTitleCase(state.subject.middleName),
      nameSuffix: toTitleCase(state.subject.nameSuffix),
    })
    if ((state.subject.addresses?.filter(x => x.id).length ?? 0) > 0) dispatch('formatSubjectAddresses')
    if ((state.subject.aliases?.filter(x => x.id).length ?? 0) > 0) dispatch('formatSubjectAliases')
    if ((state.subject.educationalInstitutions?.filter(x => x.id).length ?? 0) > 0) dispatch('formatSubjectEducationalInstitutions')
    if ((state.subject.emails?.filter(x => x.id).length ?? 0) > 0) dispatch('formatSubjectEmails')
    if ((state.subject.employees?.filter(x => x.id).length ?? 0) > 0) dispatch('formatSubjectEmployees')
    if ((state.subject.employers?.filter(x => x.id).length ?? 0) > 0) dispatch('formatSubjectEmployers')
    if ((state.subject.phoneNumbers?.filter(x => x.id).length ?? 0) > 0) dispatch('formatSubjectPhoneNumbers')
    if ((state.subject.relationships?.filter(x => x.id).length ?? 0) > 0) dispatch('formatSubjectRelationships')
    if ((state.subject.surnames?.filter(x => x.id).length ?? 0) > 0) dispatch('formatSubjectSurnames')
  },
  async formatSubjectAddresses ({ commit, state }) {
    const addresses = await this.$axios.$patch(`subjects/${state.subject.id}/addresses`, {
      addresses: state.subject.addresses.filter(x => x.id).map((address) => {
        return {
          id: address.id,
          city: toTitleCase(address.city),
          county: toTitleCase(address.county),
          country: toTitleCase(address.country),
          state: getStateAbbreviation(toTitleCase(address.state)),
          street1: toTitleCase(address.street1),
        }
      }),
    })

    const newItems = state.subject.addresses.filter(x => x.uuid)

    const subject = {
      ...state.subject,
      addresses: [...addresses || [], ...newItems],
    }

    commit('setSubject', subject)
  },
  async formatSubjectAliases ({ commit, state }) {
    const aliases = await this.$axios.$patch(`subjects/${state.subject.id}/aliases`, {
      aliases: state.subject.aliases.filter(x => x.id).map((alias) => {
        return {
          id: alias.id,
          alias: toTitleCase(alias.alias),
        }
      }),
    })

    const newItems = state.subject.aliases.filter(x => x.uuid)

    const subject = {
      ...state.subject,
      aliases: [...aliases || [], ...newItems],
    }

    commit('setSubject', subject)
  },
  async formatSubjectEducationalInstitutions ({ commit, state }) {
    const educationalInstitutions = await this.$axios.$patch(`subjects/${state.subject.id}/educational-institutions`, {
      educationalInstitutions: state.subject.educationalInstitutions.filter(x => x.id).map((educationalInstitution) => {
        return {
          id: educationalInstitution.id,
          major: toTitleCase(educationalInstitution.major),
          name: toTitleCase(educationalInstitution.name),
        }
      }),
    })

    const newItems = state.subject.educationalInstitutions.filter(x => x.uuid)

    const subject = {
      ...state.subject,
      educationalInstitutions: [...educationalInstitutions || [], ...newItems],
    }

    commit('setSubject', subject)
  },
  async formatSubjectEmails ({ commit, state }) {
    const emails = await this.$axios.$patch(`subjects/${state.subject.id}/emails`, {
      emails: state.subject.emails.filter(x => x.id).map((email) => {
        return {
          id: email.id,
          email: email.email?.toLowerCase(),
        }
      }),
    })

    const newItems = state.subject.emails.filter(x => x.uuid)

    const subject = {
      ...state.subject,
      emails: [...emails || [], ...newItems],
    }

    commit('setSubject', subject)
  },
  async formatSubjectEmployees ({ commit, state }) {
    const employees = await this.$axios.$patch(`subjects/${state.subject.id}/employees`, {
      employees: state.subject.employees.filter(x => x.id).map((employee) => {
        return {
          id: employee.id,
          firstName: toTitleCase(employee.firstName),
          middleName: toTitleCase(employee.middleName),
          lastName: toTitleCase(employee.lastName),
          position: toTitleCase(employee.position),
        }
      }),
    })

    const newItems = state.subject.employees.filter(x => x.uuid)

    const subject = {
      ...state.subject,
      employees: [...employees || [], ...newItems],
    }

    commit('setSubject', subject)
  },
  async formatSubjectEmployers ({ commit, state }) {
    const employers = await this.$axios.$patch(`subjects/${state.subject.id}/employers`, {
      employers: state.subject.employers.filter(x => x.id).map((employer) => {
        return {
          id: employer.id,
          name: toTitleCase(employer.name),
          position: toTitleCase(employer.position),
        }
      }),
    })

    const newItems = state.subject.employers.filter(x => x.uuid)

    const subject = {
      ...state.subject,
      employers: [...employers || [], ...newItems],
    }

    commit('setSubject', subject)
  },
  async formatSubjectPhoneNumbers ({ commit, state }) {
    const phoneNumbers = await this.$axios.$patch(`subjects/${state.subject.id}/phone-numbers`, {
      phoneNumbers: state.subject.phoneNumbers.filter(x => x.id).map((phoneNumber) => {
        const strippedPhone = phoneNumber.phone
          ?.match(/\d+/g)
          ?.join('') ?? ''
        return {
          id: phoneNumber.id,
          phone: strippedPhone.length === 11
            ? `+${strippedPhone.substring(0, 1)} (${strippedPhone.substring(1, 4)}) ${strippedPhone.substring(4, 7)}-${strippedPhone.substring(7)}`
            : strippedPhone.length === 7
              ? `${strippedPhone.substring(0, 3)}-${strippedPhone.substring(3)}`
              : `(${strippedPhone.substring(0, 3)}) ${strippedPhone.substring(3, 6)}-${strippedPhone.substring(6)}`,
        }
      }),
    })

    const newItems = state.subject.phoneNumbers.filter(x => x.uuid)

    const subject = {
      ...state.subject,
      phoneNumbers: [...phoneNumbers || [], ...newItems],
    }

    commit('setSubject', subject)
  },
  async formatSubjectRelationships ({ commit, state }) {
    const relationships = await this.$axios.$patch(`subjects/${state.subject.id}/relationships`, {
      relationships: state.subject.relationships.filter(x => x.id).map((relationship) => {
        return {
          id: relationship.id,
          firstName: toTitleCase(relationship.firstName),
          middleName: toTitleCase(relationship.middleName),
          lastName: toTitleCase(relationship.lastName),
        }
      }),
    })

    const newItems = state.subject.relationships.filter(x => x.uuid)

    const subject = {
      ...state.subject,
      relationships: [...relationships || [], ...newItems],
    }

    commit('setSubject', subject)
  },
  async formatSubjectSurnames ({ commit, state }) {
    const surnames = await this.$axios.$patch(`subjects/${state.subject.id}/surnames`, {
      surnames: state.subject.surnames.filter(x => x.id).map((surname) => {
        return {
          id: surname.id,
          name: toTitleCase(surname.name),
        }
      }),
    })

    const newItems = state.subject.surnames.filter(x => x.uuid)

    const subject = {
      ...state.subject,
      surnames: [...surnames || [], ...newItems],
    }

    commit('setSubject', subject)
  },
  pushFindings ({ commit, state }, findings) {
    commit('setFindings', [...state.findings, ...findings])
  },
  async removeFindingScreenshot ({ dispatch }, { findingId }) {
    const updatedFinding = await this.$axios.$delete(`findings/${findingId}/findings-screenshot`)
    dispatch('updateFindingInList', updatedFinding)
  },
  async removeSubjectEmployeeUrl ({ commit, state }, { id: itemId, uuid: itemUuid }) {
    const removedUrl = itemId
      ? await this.$axios.delete(`subject-employee-urls/${itemId}`)
      : null

    const subject = {
      ...state.subject,
      employees: state.subject.employees.map((employee) => {
        if (employee.id === removedUrl?.subjectEmployeeId) {
          employee.urls = employee.urls.filter(url => url.id !== itemId)
        }

        return employee
      }),
      employeeUrls: itemId
        ? state.subject.employeeUrls.filter(url => url.id !== itemId)
        : state.subject.employeeUrls.filter(item => item.uuid !== itemUuid),
    }

    commit('setSubject', subject)
  },
  async removeSubjectItem ({ commit, state }, { id: itemId, key, uuid: itemUuid }) {
    const subject = { ...state.subject }

    if (itemId) {
      await this.$axios.delete(`subjects/${state.subject.id}/${kebab(key)}/${itemId}`)
    }

    subject[key] = itemId
      ? subject[key].filter(({ id }) => id !== itemId)
      : subject[key].filter(item => item.uuid !== itemUuid)

    if (key === 'relationships') {
      subject.relationshipUrls = subject.relationshipUrls
        .filter(relationshipUrl => relationshipUrl.subjectRelationshipId !== itemId)
    }

    if (key === 'employees') {
      subject.employeeUrls = subject.employeeUrls
        .filter(employeeUrl => employeeUrl.subjectEmployeeId !== itemId)
    }

    commit('setSubject', subject)
  },
  async removeSubjectRelationshipUrl ({ commit, state }, { id: itemId, uuid: itemUuid }) {
    const removedUrl = itemId
      ? await this.$axios.delete(`subject-relationship-urls/${itemId}`)
      : null

    const subject = {
      ...state.subject,
      relationships: state.subject.relationships.map((relationship) => {
        if (relationship.id === removedUrl?.subjectRelationshipId) {
          relationship.urls = relationship.urls.filter(url => url.id !== itemId)
        }

        return relationship
      }),
      relationshipUrls: itemId
        ? state.subject.relationshipUrls.filter(url => url.id !== itemId)
        : state.subject.relationshipUrls.filter(item => item.uuid !== itemUuid),
    }

    commit('setSubject', subject)
  },
  async resequenceFindings ({ commit }, { deepReportId, findingId, sequence }) {
    const findings = await this.$axios.$post(`deep-reports/${deepReportId}/findings/${findingId}/resequence`, {
      sequence,
    })
    commit('setFindings', findings)
  },
  resetWorkspace ({ commit }, options) {
    const {
      companyName,
      deepReportId,
      findingCategories,
      findings,
      findingTags,
      platformTypes,
      subject,
    } = options

    commit('setCompanyName', companyName ?? null)
    commit('setDeepReportId', deepReportId)
    commit('setExpandedSubjectFields', [0, 1])
    commit('setFileUploading', false)
    commit('setFindingCategories', findingCategories ?? [])
    commit('setFindings', findings ?? [])
    commit('setFindingTags', findingTags ?? [])
    commit('setPlatformTypes', platformTypes ?? [])
    commit('setSubject', subject ?? {
      addresses: [],
      aliases: [],
      birthDate: null,
      birthDay: null,
      birthMonth: null,
      birthYear: null,
      companyName: null,
      createdAt: null,
      deletedAt: null,
      dobIsConfirmed: false,
      educationalInstitutions: [],
      emails: [],
      employeeUrls: [],
      employees: [],
      employers: [],
      estimatedAge: null,
      firstName: null,
      fullName: null,
      gender: null,
      genderIsConfirmed: false,
      id: null,
      lastName: null,
      middleName: null,
      nameIsConfirmed: false,
      phoneNumbers: [],
      relationshipUrls: [],
      relationships: [],
      surnames: [],
      type: null,
      updatedAt: null,
      urls: [],
    })
  },
  toggleExpandedSubjectFields ({ commit, state }) {
    const value = state.expandedSubjectFields.length > 0
      ? []
      : [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]

    commit('setExpandedSubjectFields', value)
  },
  toggleExpansion ({ commit }, value) {
    commit('setExpandedSubjectFields', value)
  },
  async updateFinding ({ dispatch }, { id, key, value }) {
    const serializedValue = value === '' ? null : value
    const updatedFinding = await this.$axios.$patch(`/findings/${id}`, { [key]: serializedValue })

    dispatch('updateFindingInList', updatedFinding)
  },
  updateFindingInList ({ commit, state }, finding) {
    const findings = state.findings.map((item) => {
      return item.id === finding.id
        ? { ...finding }
        : item
    })

    commit('setFindings', findings)
  },
  async updateSubject ({ commit, state }, data) {
    const response = await updateSubject.call(this, { data, subjectId: state.subject.id })

    const subject = {
      ...state.subject,
      ...response.data,
    }

    commit('setSubject', subject)
  },
  async updateSubjectEmployeeUrl ({ commit, state }, { id, value }) {
    const entries = Object.entries(value).map(([key, value]) => {
      return [key, valueFallback(value)]
    })

    const data = Object.fromEntries(entries)

    const response = await this.$axios.put(`subject-employee-urls/${id}`, data)

    const subject = {
      ...state.subject,
      employeeUrls: state.subject.employeeUrls.map((employeeUrl) => {
        if (employeeUrl.id === response.data.id) return response.data

        return employeeUrl
      }),
    }

    commit('setSubject', subject)
  },
  upsertSubjectEmployeeUrl ({ dispatch }, { id, value }) {
    const isNewItem = !id
    if (isNewItem) {
      dispatch('addSubjectEmployeeUrl', { value })
    } else {
      dispatch('updateSubjectEmployeeUrl', { id, value })
    }
  },
  upsertSubjectItem ({ dispatch }, { id, key, value }) {
    const isNewItem = !id

    if (isNewItem) {
      dispatch('addSubjectItem', { key, value })
    } else {
      dispatch('updateSubjectItem', { id, key, value })
    }
  },
  upsertSubjectRelationshipUrl ({ dispatch }, { id, value }) {
    const isNewItem = !id
    if (isNewItem) {
      dispatch('addSubjectRelationshipUrl', { value })
    } else {
      dispatch('updateSubjectRelationshipUrl', { id, value })
    }
  },
  async updateSubjectItem ({ commit, state }, { id, key, value }) {
    const entries = Object.entries(value).map(([key, value]) => {
      return [key, valueFallback(value)]
    })

    const data = Object.fromEntries(entries)

    const response = await updateSubject.call(this, { data, key, keyId: id, subjectId: state.subject.id })

    const subject = { ...state.subject }
    subject[key] = subject[key]
      .map((item) => {
        if (item.id === response.data.id) {
          item = response.data
        }

        return item
      })
    commit('setSubject', subject)
  },
  async updateSubjectKey ({ commit, dispatch, state }, { key, value }) {
    const data = { [key]: valueFallback(value) }

    const response = await updateSubject.call(this, { data, subjectId: state.subject.id })

    const subject = {
      ...state.subject,
      ...response.data,
    }

    commit('setSubject', subject)
  },
  async updateSubjectRelationshipUrl ({ commit, state }, { id, value }) {
    const entries = Object.entries(value).map(([key, value]) => {
      return [key, valueFallback(value)]
    })

    const data = Object.fromEntries(entries)

    const response = await this.$axios.put(`subject-relationship-urls/${id}`, data)

    const subject = {
      ...state.subject,
      relationshipUrls: state.subject.relationshipUrls.map((relationshipUrl) => {
        if (relationshipUrl.id === response.data.id) return response.data

        return relationshipUrl
      }),
    }

    commit('setSubject', subject)
  },
  async uploadFindingScreenshot ({ commit, dispatch }, { files, findingId, deepReportId }) {
    commit('setFileUploading', true)

    const file = files[0]
    const hashes = await calculateHashes(file)

    const { data: uploadUrl } = await this.$axios.post(`deep-reports/${deepReportId}/upload-screenshot-link`, {
      fileName: file.name,
      findingId,
    })

    const axios = require('axios').default
    const response = await axios.put(uploadUrl, file, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })

    if (!response.status === 200) {
      commit('setFileUploading', false)
      return
    }

    const updatedFinding = await this.$axios.$patch(`findings/${findingId}`, {
      screenshot: file.name,
      screenshotHash: hashes.sha256,
      screenshotVersion: response.headers['x-amz-version-id'],
    })

    dispatch('updateFindingInList', updatedFinding)
    commit('setFileUploading', false)
  },
  async uploadScreenshotSource ({ commit, dispatch }, { file, findingId, deepReportId }) {
    commit('setFileUploading', true)

    const hashes = await calculateHashes(file)

    const { data: uploadUrl } = await this.$axios.post(`deep-reports/${deepReportId}/upload-screenshot-link`, {
      fileName: file.name,
      findingId,
    })

    const axios = require('axios').default
    const response = await axios.put(uploadUrl, file, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })

    if (!response.status === 200) {
      commit('setFileUploading', false)
      return
    }

    const updatedFinding = await this.$axios.$patch(`findings/${findingId}`, {
      source: file.name,
      sourceHash: hashes.sha256,
      sourceVersion: response.headers['x-amz-version-id'],
    })

    dispatch('updateFindingInList', updatedFinding)
    commit('setFileUploading', false)
  },
}
