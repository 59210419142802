export default {
  addNotification ({ commit }, notification) {
    commit('pushNotification', notification)
    commit('newNotification', true)
  },
  fetchNextNotification ({ commit, state }) {
    const notification = state.notifications[state.notifications.length - 1]
    commit('popNotification', notification)

    if (state.notifications.length === 0) {
      commit('newNotification', false)
    }

    return notification
  },
  hideBanner ({ commit }) {
    commit('closeBanner')
  },
  setBanner ({ commit }, banner) {
    commit('setBanner', banner)
  },

}
