
export default {
  layout: 'error',
  props: {
    error: {
      type: Object,
      default: null,
    },
  },
  head () {
    const title = `${this.errorHeader} - ${this.errorCopy}`
    return {
      title,
    }
  },
  computed: {
    errorCopy () {
      return (this.isDev && this.error?.statusText) ||
        (this.statusCode === 404
          ? 'Not Found'
          : 'An error has occurred')
    },
    errorHeader () {
      if (this.isDev) return this.statusCode

      return [404, 500].includes(this.statusCode)
        ? this.statusCode
        : 'error'
    },
    errorResponseComponent () {
      return this.error?.response && [422].includes(this.error.response.status)
        ? `ResponseError${this.error.response?.status}`
        : null
    },
    errorStack () {
      return this.error?.stack
        ? this.error?.stack.split('\n')
        : []
    },
    isDev () {
      return this.$config?.environment === 'development'
    },
    requestData () {
      let body

      if (!this.error.response?.config?.data) return null

      try {
        body = JSON.stringify(JSON.parse(this.error.response?.config?.data), null, 2)
      } catch {
        body = null
      }

      return body
    },
    statusCode () {
      return this.error?.statusCode ?? 'Error'
    },
  },
}
