// Both methods of exporting are needed here or functionality breaks.

export const kebab = value => value
  ?.replace(/([a-z])([A-Z])/g, '$1-$2')
  ?.replace(/\s+/g, '-')
  ?.toLowerCase()

export const snake = value => value
  ?.replace(/([a-z])([A-Z])/g, '$1_$2')
  ?.replace(/\s+/g, '_')
  ?.toLowerCase()

export const title = (str) => {
  if (!str || typeof str !== 'string') {
    return str
  }

  return str.toLowerCase().replace(/(?:^|[\s-])\w/g, (match) => {
    return match.toUpperCase()
  })
}

export default {
  kebab,
  snake,
  title,
}
