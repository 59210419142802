export default {
  closeBanner (state) {
    state.banner.show = false
    state.banner.closedByUser = true
  },
  setBanner (state, { show, message }) {
    state.banner.show = show
    state.banner.closedByUser = false
    state.banner.message = message
  },
  newNotification (state, value) {
    state.newNotification = value
  },
  pushNotification (state, value) {
    state.notifications.push(value)
  },
  popNotification (state) {
    state.notifications.pop()
  },
}
