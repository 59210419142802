
export default {
  props: {
    color: {
      default: 'background3',
      required: false,
      type: String,
    },
  },
}
