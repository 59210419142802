export default (ctx) => {
  return {
    calendarEvents: [],
    dashboardSettings: {
      allocateRbRevenue: localStorage.getItem('allocateRbRevenue') || 'true',
      detailLevel: localStorage.getItem('detailLevel') || 'compact',
      weekends: localStorage.getItem('weekends') || 'no',
      holidays: localStorage.getItem('holidays') || 'no',
    },
  }
}
